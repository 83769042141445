<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form ref="form">
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  sm12
                  md12>
                  <tab-header
                    :tab-items="filterByTabItems"
                    @onSelectTab="onFilterBy" />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="search.emailOrPhone"
                    :label="$t('Email hoặc số điện thoại')"
                    :name="$t('Email hoặc số điện thoại')"
                    type="text"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-text-field
                    v-model="search.code"
                    :label="$t('productOrder.code')"
                    :name="$t('productOrder.code')"
                    type="text"
                  />
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="search.status"
                    :items="productOrderStatusList"
                    :label="$t('common.status')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="agentId"
                    :items="agents"
                    :label="$t('entity.agent')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="storeChannelId"
                    :items="storeChannels"
                    :label="$t('storeChannel.title')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md4>
                  <v-select
                    v-model="paymentMethod.id"
                    :items="paymentMethod.list"
                    :label="$t('paymentMethod.title')"
                  >
                    <template
                      slot="selection"
                      slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
                <!-- Start date -->
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="menuStartDate"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    lazy
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="$t('evoucher.issuance.fromDate')"
                        persistent-hint
                        @blur="
                          date = parseDate(startDateFormatted)
                        "
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      :min="minStartDate"
                      no-title
                      @input="menuStartDate = false"
                    />
                  </v-menu>
                </v-flex>
                <!-- End date -->
                <v-flex
                  xs12
                  md2>
                  <v-menu
                    ref="endStartDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    lazy
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDateFormatted"
                        :label="$t('evoucher.issuance.toDate')"
                        persistent-hint
                        @blur="
                          date = parseDate(endDateFormatted)
                        "
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      :min="minEndDate"
                      no-title
                      @input="menuEndDate = false"
                    />
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="filterByOrder ? getOrdersVoucherProduct() : getVoucherProductsInOrder()"
              >
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          v-if="filterByOrder"
          :title="$t('productOrder.titleList')"
          color="green"
          flat
          full-width
        >
          <div class="py-4">
            <tab-header
              :tab-items="orderStatusTabItems"
              @onSelectTab="onSearchStatusList" />
          </div>
          <v-btn
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon
              class="mr-2"
              size="18">mdi-file-export</v-icon>
            <span style="text-transform: none;">{{
              $t("voucherReport.exportExcel")
            }}</span>
          </v-btn>
          <v-btn
            v-if="isAccountant()"
            color="success"
            dark
            style="float: right;"
            @click="onShowModalImportExcelFileToApproveOrder"
          >
            <v-icon
              class="mr-2"
              size="18">mdi-file-import</v-icon>
            <span style="text-transform: none;">{{
              $t("importExcelApproveOrder.inputFileExcel")
            }}</span>
          </v-btn>
          <a
            v-if="isAccountant()"
            :href="domainPath + `/templates/approved_product_order.xlsx`"
            type="button"
            style="float: right;"
            class="v-btn theme--dark warning"
            download>
            <v-icon
              class="mr-2"
              color="white">mdi-download</v-icon> <span style="text-transform: none;">{{ $t('common.downloadTemplate') }}</span>
          </a>
          <!-- <v-btn
            v-if="isAccountant()"
            color="warning"
            dark
            style="float: right;"
            @click="onShowModalImportExcelFileToApproveOrder"
          >
            <v-icon
              class="mr-2"
              size="18">mdi-file-import</v-icon>
            <span style="text-transform: none;">{{
              $t("Tải file mẫu")
            }}</span>
          </v-btn> -->
          <v-btn
            v-if="selected.length > 0"
            :loading="isLoadingPaymentConfirmBulk"
            color="success"
            dark
            style="float: right;"
            @click="showModalApprovedBulkOrderProduct"
          >
            <v-icon
              class="mr-2"
              size="18">mdi-check-outline</v-icon>
            <span style="text-transform: none;">{{
              $t("productOrder.approveSelectedOrders")
            }}</span>
          </v-btn>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="newOrderHeaders"
            :items="productOrderList"
            :single-select="true"
            v-model="selected"
            :select-all="!paidStatusSelected ? true : false"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props">
              <td v-show="!paidStatusSelected">
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details />
              </td>
              <td>{{ props.item.stt }}</td>
              <td
                style="color: #3f51b5!important; cursor: pointer;"
                @click="onShowModalOrderDetail(props.item)"
              >
                {{ props.item.orderCode }}
              </td>
              <td
                style="color: #3f51b5!important; cursor: pointer;"
                @click="onShowModalOrderDetail(props.item)"
              >
                {{ props.item.fullname }}
              </td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.phone }}</td>
              <td>{{ props.item.date }}</td>
              <td v-if="search.statusList.length == 2">{{ props.item.paidDate }}</td>
              <td>{{ props.item.agent }}</td>
              <td>
                <v-chip
                  :color="getColor(props.item)"
                  dark>
                  {{ getStatus(props.item) }}
                </v-chip>
              </td>
              <td>{{ formatMoney(props.item.totalPrice) + " VND" }}</td>
              <!-- <td>{{ props.item.discount }}</td> -->
              <td>{{ $t(getPaymentMethodName(props.item.paymentMethod)) }}</td>
              <td>{{ props.item.storeChannel }}</td>
              <td
                v-if="
                  isAccountant() && !paidStatusSelected
                "
                style="white-space: nowrap;"
              >
                <v-tooltip
                  top
                  class="mr-2">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="error"
                      dark
                      v-on="on"
                      @click="onShowModalCancelOrderProduct(props.item)"
                    >mdi-close-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("productOrder.btn.orderCancellation") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      dark
                      v-on="on"
                      @click="onShowModalApprovedOrderProduct(props.item)"
                    >mdi-check-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("productOrder.btn.orderConfirmation") }}</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <pagination
            :total="productPaginate.totalPage"
            :current-page="productPaginate.currentPage"
            :row-per-page="productPaginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </material-card>
        <!-- Products in order -->
        <material-card
          v-else
          :title="$t('productOrder.titleList')"
          color="green"
          flat
          full-width
        >
          <v-btn
            color="success"
            dark
            style="float: right;"
            @click="onExportToExcelFile"
          >
            <v-icon>mdi-file-export</v-icon>
            <span style="text-transform: none;">{{
              $t("voucherReport.exportExcel")
            }}</span>
          </v-btn>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="productInOrderHeaders"
            :items="productInOrderList"
            :single-select="true"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <td>{{ item.stt }}</td>
              <td>{{ item.orderCode }}</td>
              <td>{{ item.dateCreated }}</td>
              <td>{{ item.voucherSerial }}</td>
              <td>{{ item.voucherName }}</td>
              <td>{{ item.receiptDate }}</td>
              <td>{{ item.customer }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.agent }}</td>
              <td>{{ getPaymentMethodName(item.paymentMethod) }}</td>
              <td>{{ item.storeChannel }}</td>
              <td>{{ formatMoney(item.price) }}</td>
            </template>
          </v-data-table>
          <pagination
            :total="productInOrderPaginate.totalPage"
            :current-page="productInOrderPaginate.currentPage"
            :row-per-page="productInOrderPaginate.rowPerPage"
            @onPageChange="onPageProductInOrderChange"
          />
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <loadingBar :is-loading="isLoading" />
    <orderDetailModal ref="orderDetailModal" />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="
        confirmActionTypeSelected == confirmActionType.PAYMENT_CONFIRM
          ? onApprovedOrderProduct()
          : (confirmActionTypeSelected == confirmActionType.PAYMENT_CANCEL ? onCancelOrderProduct() : onApproveBulk())
      "
    />
    <importExcelApprovedOrder
      ref="importExcelFileToApproveOrder"
      @success="onImportSuccess" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import ActionVoucherProductType from 'enum/actionVoucherProductType'
import ProductOrderStatusEnum from 'enum/productOrderStatusEnum'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
import dateUtils from 'utils/dateUtils'
import stringUtils from 'utils/stringUtils'
import OrderDetailModal from './OrderDetailModal.vue'
import EntityRoleType from 'enum/entityRoleType'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import XLSX from 'xlsx'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import moment from 'moment'
import ImportExcelApprovedOrder from './ImportExcelApprovedOrder.vue'
import TabHeader from 'Components/TabHeader.vue'
import PaymentMethodType from 'enum/paymentMethodType'
export default {
  components: {
    OrderDetailModal,
    ConfirmModal,
    ImportExcelApprovedOrder,
    TabHeader
  },
  data: () => ({
    orderHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'productOrder.title',
        value: 'orderCode'
      },
      {
        sortable: false,
        text: 'customer.nameCustomer',
        value: 'fullname'
      },
      {
        sortable: false,
        text: 'common.email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'common.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'users.dateCreated',
        value: 'date'
      },
      {
        sortable: false,
        text: 'productOrder.paidDate',
        value: 'paidDate'
      },
      {
        sortable: false,
        text: 'entity.agent',
        value: 'agent'
      },
      {
        sortable: false,
        text: 'supplierAccessCode.status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'workpieceDetail.totalPrice',
        value: 'totalPrice'
      },
      // {
      //   sortable: false,
      //   text: 'evoucher.discountPrice',
      //   value: 'discount'
      // },
      {
        sortable: false,
        text: 'paymentMethod.title',
        value: 'paymentMethod'
      },
      {
        sortable: false,
        text: 'Kênh bán hàng',
        value: 'storeChannel'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions'
      }
    ],
    productOrderList: [],
    productPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    category: {
      id: null,
      list: []
    },
    categoryId: null,
    confirmModalTitle: null,
    isLoading: false,
    noImg: require('@/assets/noimage.png'),
    code: null,
    actionVoucherProductType: {
      UPDATE_PRODUCT_INFO: ActionVoucherProductType.UPDATE_PRODUCT_INFO,
      PUBLIC_ON_PORTAL: ActionVoucherProductType.PUBLIC_ON_PORTAL
    },
    productOrderStatusList: [
      { text: '---', value: null },
      ...stringUtils.ProductOrderStatusList
    ],
    search: {
      status: null,
      code: null,
      statusList: [ProductOrderStatusEnum.WAIT_FOR_PAY],
      emailOrPhone: null
    },
    confirmActionType: {
      PAYMENT_CONFIRM: 1,
      PAYMENT_CANCEL: 2,
      PAYMENT_CONFIRM_BULK: 3
    },
    productOrderStatusEnum: {
      WAIT_FOR_PAY: ProductOrderStatusEnum.WAIT_FOR_PAY,
      DELIVERED: ProductOrderStatusEnum.DELIVERED,
      CANCEL: ProductOrderStatusEnum.CANCEL
    },
    confirmActionTypeSelected: null,
    orderItemSelected: null,
    paidStatusSelected: false,
    filterByOrder: true,
    filterByTabItems: [
      {
        title: 'productOrder.byOrder',
        id: 1
      },
      {
        title: 'productOrder.byVoucher',
        id: 0
      }
    ],
    productInOrderPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    productInOrderHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'productOrder.title',
        value: 'orderCode'
      },
      {
        sortable: false,
        text: 'productOrder.orderDate',
        value: 'dateCreated'
      },
      {
        sortable: false,
        text: 'evoucher.voucherSerial',
        value: 'voucherSerial'
      },
      {
        sortable: false,
        text: 'evoucher.name',
        value: 'voucherName'
      },
      {
        sortable: false,
        text: 'createVoucher.receivedDate',
        value: 'receiptDate'
      },
      {
        sortable: false,
        text: 'evoucher.customer',
        value: 'customer'
      },
      {
        sortable: false,
        text: 'Email',
        value: 'email'
      },
      {
        sortable: false,
        text: 'common.phone',
        value: 'phone'
      },
      {
        sortable: false,
        text: 'entity.agent',
        value: 'agent'
      },
      {
        sortable: false,
        text: 'paymentMethod.title',
        value: 'paymentMethod'
      },
      {
        sortable: false,
        text: 'Kênh bán hàng',
        value: 'storeChannel'
      },
      {
        sortable: false,
        text: 'promotionCode.price',
        value: 'price'
      }
    ],
    productInOrderList: [],
    newOrderHeaders: [],
    agents: [{ value: null, text: '---' }],
    agentId: null,
    storeChannels: [{ value: null, text: '---' }],
    storeChannelId: null,
    // Start issue expired date to customer
    startDate: null,
    startDateFormatted: null,
    menuStartDate: false, // End issue expired date
    minStartDate: null,
    // Start issue expired date to customer
    endDate: null,
    endDateFormatted: null,
    menuEndDate: false, // End issue expired date
    minEndDate: null,
    selected: [],
    isLoadingPaymentConfirmBulk: false,
    paymentMethod: {
      list: [{ value: null, text: '---' }],
      id: null
    },
    holdingTime: 0,
    orderStatusTabItems: [
      {
        title: 'productOrder.status.noProcess',
        id: 0
      },
      {
        title: 'productOrder.status.processed',
        id: 1
      }
    ],
    objectSettingKey: stringUtils.ObjectSettingKey,
    domainPath: stringUtils.DOMAIN_BASE_PATH_ADMIN
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_PRODUCT_ORDERS_LIST_DATA',
      'GET_CURRENT_ACCOUNT',
      'OBJECT_SETTING_DATA'
    ])
  },
  watch: {
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      let settingData = data.filter(element => {
        return element.key === this.objectSettingKey.HOLDING_TIME
      })
      if (settingData.length > 0) {
        this.holdingTime = settingData[0].value
      }
    },
    /**
     * Issue expired date to customer
     */
    startDate (val) {
      this.startDateFormatted = this.formatDate(this.startDate)
      if (this.startDate >= this.endDate) {
        this.endDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
      }
      this.minEndDate = moment(this.startDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    /**
     * Issue expired date to customer
     */
    endDate (val) {
      this.endDateFormatted = this.formatDate(this.endDate)
    }
  },
  created () {
    if (this.filterByOrder) {
      this.getOrdersVoucherProduct()
    } else {
      this.getVoucherProductsInOrder()
    }
    this.newOrderHeaders = [...this.orderHeaders]
    // eslint-disable-next-line eqeqeq
    if (this.search.statusList.length == 1) {
      this.newOrderHeaders = this.orderHeaders.filter(element => {
        return element.value !== 'paidDate'
      })
    }
    this.GET_ENTITIES_OF_USER().then(
      function (res) {
        let data = res.data
        this.agents = [{ value: null, text: '---' }]
        for (let i = 0, size = data.length; i < size; i++) {
          this.agents.push({ value: data[i].id, text: data[i].name })
        }
        this.filterByOrder = true
      }.bind(this)
    )
    this.GET_STORE_CHANNELS_LIST().then(
      function (res) {
        let data = res.data
        this.storeChannels = [{ value: null, text: '---' }]
        for (let i = 0, size = data.length; i < size; i++) {
          let storeChannelObj = {
            value: data[i].id,
            text: data[i].name
          }
          this.storeChannels.push(storeChannelObj)
        }
      }.bind(this)
    )
    let methodList = [PaymentMethodType.CASH_ON_DELIVERY,
      PaymentMethodType.BANK_TRANSFER,
      // PaymentMethodType.MOMO_WALLET,
      // PaymentMethodType.ZALOPAY_WALLET,
      PaymentMethodType.VIET_WALLET]
    this.paymentMethod.list = [{ value: null, text: '---' }]
    for (let i = 0, size = methodList.length; i < size; i++) {
      let obj = {
        value: methodList[i],
        text: functionUtils.paymentMethodTypeName(methodList[i])
      }
      this.paymentMethod.list.push(obj)
    }
    let entityId = sessionStorage.getItem('entityId')
    let filter = {
      params: {
        objectId: entityId,
        objectType: 'Entity'
      }
    }
    this.GET_OBJECT_SETTING(filter)
  },
  methods: {
    /**
     * Import sucess
     */
    onImportSuccess: function () {
      if (this.filterByOrder) {
        this.getOrdersVoucherProduct()
      } else {
        this.getVoucherProductsInOrder()
      }
    },
    /**
     * Import excel file to approve order
     */
    onShowModalImportExcelFileToApproveOrder: function () {
      this.$refs.importExcelFileToApproveOrder.onShowModal()
    },
    /**
     * Approve bulk
     */
    onApproveBulk: function () {
      for (let i = 0, size = this.selected.length; i < size; i++) {
        // eslint-disable-next-line eqeqeq
        if (this.selected[i].status == ProductOrderStatusEnum.DELIVERED || this.selected[i].status == ProductOrderStatusEnum.CANCEL) {
          this.ON_SHOW_TOAST({
            message: this.$t('productOrder.cannotApprovedOrdersInvalid'),
            styleType: ToastType.ERROR
          })
          return
        }
      }
      let filter = {
        orderList: this.selected
      }
      this.isLoadingPaymentConfirmBulk = true
      this.PAYMENT_CONFIRMATION_BULK(filter)
        .then(
          function () {
            this.isLoadingPaymentConfirmBulk = false
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getOrdersVoucherProduct()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoadingPaymentConfirmBulk = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * Page product in order change
     */
    onPageProductInOrderChange: function (page) {
      this.productInOrderPaginate.currentPage = page
      this.getVoucherProductsInOrder()
    },
    /**
     * Filter by
     */
    onFilterBy: function ({ index, item }) {
      this.filterByOrder = item.id === 1
      if (this.filterByOrder) {
        this.getOrdersVoucherProduct()
      } else {
        this.getVoucherProductsInOrder()
      }
    },
    /**
     * Get voucher products in order
     */
    getVoucherProductsInOrder: function () {
      let filter = {
        params: {
          page: this.productInOrderPaginate.currentPage,
          orderCode: this.search.code,
          agentId: this.agentId,
          storeChannelId: this.storeChannelId,
          emailOrPhone: this.search.emailOrPhone ? this.search.emailOrPhone.trim() : null,
          paymentMethod: this.paymentMethod.id
        }
      }
      this.isLoading = true
      this.GET_VOUCHER_PRODUCT_IN_ORDERS(filter).then(
        function (res) {
          let data = res.data
          // Handle paginate
          this.productInOrderPaginate.currentPage = data.page
          this.productInOrderPaginate.totalPage = data.num_pages
          this.productInOrderPaginate.rowPerPage = data.per_page / 2
          // Handle response data
          let productData = data.results
          this.productInOrderList = []
          for (let i = 0, size = productData.length; i < size; i++) {
            let object = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              orderCode: productData[i].order.code,
              dateCreated: dateUtils.formatBeautyDate(productData[i].order.date_created),
              voucherSerial: productData[i].voucher.serial,
              voucherName: productData[i].voucher.name,
              price: productData[i].voucher.price,
              customer: functionUtils.concatFullname(productData[i].customer.last_name, productData[i].customer.first_name),
              email: productData[i].customer.email,
              receiptDate: dateUtils.formatBeautyDate(productData[i].order.date_updated),
              agent: productData[i].entity,
              paymentMethod: productData[i].payment_method,
              phone: productData[i].customer.phone,
              storeChannel: productData[i].store_channel.name
            }
            this.productInOrderList.push(object)
          }
          this.isLoading = false
        }.bind(this)
      )
    },
    // Rename key
    renameKey: function (object, key, newKey) {
      const targetKey = object[key]
      delete object[key]
      object[newKey] = targetKey
      return object
    },
    /**
     * Handle export excel by order
     */
    onHandleExportExcelByOrder: function () {
      this.isLoading = true
      let startTime = dateUtils.formatCompareDate(this.startDateFormatted, null)
      let endTime = dateUtils.formatCompareDate(this.endDateFormatted, null)
      let filter = {
        params: {
          code: this.search.code ? this.search.code.trim() : null,
          status: this.search.status,
          statusList: this.search.statusList,
          emailOrPhone: this.search.emailOrPhone ? this.search.emailOrPhone.trim() : null,
          agentId: this.agentId,
          storeChannelId: this.storeChannelId,
          startDate: startTime,
          endDate: endTime,
          paymentMethod: this.paymentMethod.id
        }
      }
      this.GET_VOUCHER_PRODUCT_ORDERS(filter)
        .then(
          function (res) {
            let data = res.data
            let vouchers = []
            for (let i = 0, size = data.length; i < size; i++) {
              let dateCreated = dateUtils.formatBeautyDate(data[i].date_created)
              let voucherObj = {
                stt: i + 1,
                orderCode: data[i].code,
                date: dateCreated,
                status: data[i].status,
                totalPrice: this.getTotalPrice(data[i].items),
                fullname: functionUtils.concatFullname(data[i].user_order.last_name, data[i].user_order.first_name),
                id: data[i].id,
                email: data[i].user_order.email,
                paidDate: dateUtils.formatBeautyDate(data[i].date_updated),
                agent: data[i].entity.name,
                discount: this.getDiscount(
                  data[i].promotion_type,
                  data[i].promotion_value
                ),
                phone: data[i].user_order.phone,
                paymentMethod: functionUtils.paymentMethodTypeName(data[i].payment_method),
                storeChannel: data[i].store_channel.name,
                deadlinePayment: moment(dateCreated, 'DD/MM/YYYY HH:mm').add(this.holdingTime, 'hours').format('YYYY-MM-DD HH:mm')
              }
              vouchers.push(voucherObj)
            }
            let newData = []
            let obj = {}
            // Get key customize (Only show keys selected)
            for (let i = 0, size = vouchers.length; i < size; i++) {
              for (var property in vouchers[i]) {
                for (
                  let j = 0, headersSize = this.newOrderHeaders.length;
                  j < headersSize;
                  j++
                ) {
                  if (this.newOrderHeaders[j].value === property) {
                    if (property === 'status') {
                      obj[property] = this.$t(
                        this.getStatus(vouchers[i])
                      )
                    } else {
                      obj[property] = vouchers[i][property]
                    }
                  }
                }
              }
              newData.push(obj)
              obj = {}
            }
            // Convert code to text
            for (let k = 0, size3 = newData.length; k < size3; k++) {
              for (var property2 in newData[k]) {
                if (property2 === 'discount') {
                  this.renameKey(newData[k], property2, this.$t('Giảm giá'))
                }
                if (property2 === 'agent') {
                  this.renameKey(newData[k], property2, this.$t('Đại lý'))
                }
                if (property2 === 'paidDate') {
                  this.renameKey(newData[k], property2, this.$t('Ngày thanh toán'))
                }
                if (property2 === 'email') {
                  this.renameKey(newData[k], property2, this.$t('Email'))
                }
                if (property2 === 'phone') {
                  this.renameKey(newData[k], property2, this.$t('common.phone'))
                }
                if (property2 === 'paymentMethod') {
                  this.renameKey(newData[k], property2, this.$t('paymentMethod.title'))
                }
                if (property2 === 'orderCode') {
                  this.renameKey(newData[k], property2, this.$t('Mã đơn hàng'))
                }
                if (property2 === 'date') {
                  this.renameKey(newData[k], property2, this.$t('Ngày tạo'))
                }
                if (property2 === 'storeChannel') {
                  this.renameKey(newData[k], property2, this.$t('Kênh bán hàng'))
                }
                if (property2 === 'fullname') {
                  this.renameKey(newData[k], property2, this.$t('Tên khách hàng'))
                }
                if (property2 === 'totalPrice') {
                  this.renameKey(newData[k], property2, this.$t('Tổng tiền'))
                }
                if (property2 === 'status') {
                  this.renameKey(newData[k], property2, this.$t('Trạng thái'))
                }
                if (property2 === 'stt') {
                  this.renameKey(newData[k], property2, this.$t('common.nonumber'))
                }
              }
            }
            // export json to Worksheet of Excel
            // only array possible
            var vouchersReport = XLSX.utils.json_to_sheet(newData)
            // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)
            // A workbook is the name given to an Excel file
            var wb = XLSX.utils.book_new() // make Workbook of Excel
            // add Worksheet to Workbook
            // Workbook contains one or more worksheets
            XLSX.utils.book_append_sheet(
              wb,
              vouchersReport,
              this.$t('Thống kê theo đơn hàng')
            ) // sheetAName is name of Worksheet
            // export Excel file
            XLSX.writeFile(wb, this.$t('Thống kê theo đơn hàng') + '.xlsx') // name of the file is 'book.xlsx'
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Export to excel file
     */
    onExportToExcelFile: function () {
      if (this.filterByOrder) {
        this.onHandleExportExcelByOrder()
      } else {
        this.onHandleExportExcelByProductInOrder()
      }
    },
    /**
     * Handle export excel by product in order
     */
    onHandleExportExcelByProductInOrder: function () {
      this.isLoading = true
      let filter = {
        params: {
          orderCode: this.search.code,
          agentId: this.agentId,
          storeChannelId: this.storeChannelId,
          emailOrPhone: this.search.emailOrPhone ? this.search.emailOrPhone.trim() : null,
          paymentMethod: this.paymentMethod.id
        }
      }
      this.GET_VOUCHER_PRODUCT_IN_ORDERS(filter)
        .then(
          function (res) {
            let data = res.data
            let vouchers = []
            for (let i = 0, size = data.length; i < size; i++) {
              let voucherObj = {
                stt: i + 1,
                orderCode: data[i].order.code,
                dateCreated: dateUtils.formatBeautyDate(data[i].order.date_created),
                voucherSerial: data[i].voucher.serial,
                voucherName: data[i].voucher.name,
                price: data[i].voucher.price,
                customer: functionUtils.concatFullname(data[i].customer.last_name, data[i].customer.first_name),
                email: data[i].customer.email,
                receiptDate: dateUtils.formatBeautyDate(data[i].order.date_updated),
                agent: data[i].entity,
                paymentMethod: functionUtils.paymentMethodTypeName(data[i].payment_method),
                phone: data[i].customer.phone,
                storeChannel: data[i].store_channel.name
              }
              vouchers.push(voucherObj)
            }
            let newData = []
            let obj = {}
            // Get key customize (Only show keys selected)
            for (let i = 0, size = vouchers.length; i < size; i++) {
              for (var property in vouchers[i]) {
                for (
                  let j = 0, headersSize = this.productInOrderHeaders.length;
                  j < headersSize;
                  j++
                ) {
                  if (this.productInOrderHeaders[j].value === property) {
                    obj[property] = vouchers[i][property]
                  }
                }
              }
              newData.push(obj)
              obj = {}
            }
            // Convert code to text
            for (let k = 0, size3 = newData.length; k < size3; k++) {
              for (var property2 in newData[k]) {
                if (property2 === 'receiptDate') {
                  this.renameKey(newData[k], property2, this.$t('Ngày nhận'))
                }
                if (property2 === 'agent') {
                  this.renameKey(newData[k], property2, this.$t('Đại lý'))
                }
                if (property2 === 'voucherSerial') {
                  this.renameKey(newData[k], property2, this.$t('Số seri'))
                }
                if (property2 === 'voucherName') {
                  this.renameKey(newData[k], property2, this.$t('Tên voucher'))
                }
                if (property2 === 'email') {
                  this.renameKey(newData[k], property2, this.$t('Email'))
                }
                if (property2 === 'phone') {
                  this.renameKey(newData[k], property2, this.$t('common.phone'))
                }
                if (property2 === 'paymentMethod') {
                  this.renameKey(newData[k], property2, this.$t('paymentMethod.title'))
                }
                if (property2 === 'orderCode') {
                  this.renameKey(newData[k], property2, this.$t('Mã đơn hàng'))
                }
                if (property2 === 'dateCreated') {
                  this.renameKey(newData[k], property2, this.$t('Ngày đặt'))
                }
                if (property2 === 'storeChannel') {
                  this.renameKey(newData[k], property2, this.$t('Kênh bán hàng'))
                }
                if (property2 === 'customer') {
                  this.renameKey(
                    newData[k],
                    property2,
                    this.$t('Khách hàng')
                  )
                }
                if (property2 === 'price') {
                  this.renameKey(newData[k], property2, this.$t('Giá tiền'))
                }
                if (property2 === 'stt') {
                  this.renameKey(
                    newData[k],
                    property2,
                    this.$t('common.nonumber')
                  )
                }
              }
            }
            // export json to Worksheet of Excel
            // only array possible
            var vouchersReport = XLSX.utils.json_to_sheet(newData)
            // var pokemonWS = XLSX.utils.json_to_sheet(this.Datas.pokemons)
            // A workbook is the name given to an Excel file
            var wb = XLSX.utils.book_new() // make Workbook of Excel
            // add Worksheet to Workbook
            // Workbook contains one or more worksheets
            XLSX.utils.book_append_sheet(
              wb,
              vouchersReport,
              this.$t('Thống kê theo voucher')
            ) // sheetAName is name of Worksheet
            // export Excel file
            XLSX.writeFile(wb, this.$t('Thống kê theo voucher') + '.xlsx') // name of the file is 'book.xlsx'
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Get discount
     */
    getDiscount: function (type, value) {
      if (value) {
        // eslint-disable-next-line eqeqeq
        if (VoucherProductPromotionType.PRICE == type) {
          return this.formatMoney(value)
          // eslint-disable-next-line eqeqeq
        } else if (VoucherProductPromotionType.PERCENT == type) {
          return value + ' %'
        }
      } else {
        return ''
      }
    },
    /**
     * Search status list
     */
    onSearchStatusList: function ({ index, item }) {
      this.paidStatusSelected = item.id === 1
      if (this.paidStatusSelected) {
        this.search.statusList = [ProductOrderStatusEnum.CANCEL, ProductOrderStatusEnum.DELIVERED]
        this.newOrderHeaders = this.orderHeaders
      } else {
        this.search.statusList = [ProductOrderStatusEnum.WAIT_FOR_PAY]
        this.newOrderHeaders = this.orderHeaders.filter(element => {
          return element.value !== 'paidDate'
        })
      }
      this.getOrdersVoucherProduct()
    },
    /**
     * Show modal cancel order product
     */
    onShowModalCancelOrderProduct: function (item) {
      this.confirmActionTypeSelected = this.confirmActionType.PAYMENT_CANCEL
      this.orderItemSelected = item
      this.confirmModalTitle = this.$t('productOrder.modalConfirmTitle.cancelOrder')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Show modal approved order product
     */
    onShowModalApprovedOrderProduct: function (item) {
      this.confirmActionTypeSelected = this.confirmActionType.PAYMENT_CONFIRM
      this.orderItemSelected = item
      this.confirmModalTitle = this.$t('productOrder.modalConfirmTitle.approveSingle')
      this.$refs.confirmModal.onShowModal()
    },
    showModalApprovedBulkOrderProduct: function () {
      this.confirmActionTypeSelected = this.confirmActionType.PAYMENT_CONFIRM_BULK
      this.confirmModalTitle = this.$t('productOrder.modalConfirmTitle.approveBulk')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Cancel order product
     */
    onCancelOrderProduct: function () {
      let filter = {
        id: this.orderItemSelected.id
      }
      this.CANCEL_VOUCHER_PRODUCT_ORDER(filter)
        .then(
          function () {
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getOrdersVoucherProduct()
          }.bind(this)
        )
        .catch(
          function (error) {
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * Approved order product
     */
    onApprovedOrderProduct: function () {
      let filter = {
        productOrderId: this.orderItemSelected.id,
        productList: this.orderItemSelected.voucherList
      }
      this.isLoading = true
      this.PAYMENT_CONFIRMATION(filter)
        .then(
          function () {
            this.isLoading = false
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
            this.getOrdersVoucherProduct()
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styletype: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    /**
     * CHeck accountant role
     */
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    /**
     * Show modal order detail
     */
    onShowModalOrderDetail: function (item) {
      this.$refs.orderDetailModal.onShowModal(item)
    },
    /**
     * Get color
     */
    getColor (item) {
      let currentDate = moment().format('YYYY-MM-DD HH:mm')
      // eslint-disable-next-line eqeqeq
      if (item.status == ProductOrderStatusEnum.CANCEL) return 'red'
      // eslint-disable-next-line eqeqeq
      else if (item.status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        // eslint-disable-next-line eqeqeq
        if (this.holdingTime && this.holdingTime != 0 && item.deadlinePayment && currentDate > item.deadlinePayment) {
          return 'pinky'
        }
        return 'orange'
      } else if (item.status === ProductOrderStatusEnum.DELIVERED) {
        return 'blue'
      } else return 'green'
    },
    /**
     * Get status
     */
    getStatus: function (item) {
      let currentDate = moment().format('YYYY-MM-DD HH:mm')
      // eslint-disable-next-line eqeqeq
      if (item.status == ProductOrderStatusEnum.DELIVERED) {
        return this.$t('productOrder.status.delivered')
        // eslint-disable-next-line eqeqeq
      } else if (item.status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        // eslint-disable-next-line eqeqeq
        if (this.holdingTime && this.holdingTime != 0 && item.deadlinePayment && currentDate > item.deadlinePayment) {
          item.paymentOverdue = true
          return this.$t('productOrder.status.paymentOverdue')
        }
        return this.$t('productOrder.status.waitForPay')
        // eslint-disable-next-line eqeqeq
      } else if (item.status == ProductOrderStatusEnum.CANCEL) {
        return this.$t('productOrder.status.cancel')
      } else if (item.status === ProductOrderStatusEnum.PAID_ONLINE) {
        return this.$t('productOrder.status.paid')
      }
    },
    getTotalPrice: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(items[i].price)
          ? items[i].price : items[i].workpiece.value
        let promotionValue = items[i].product_promotion && items[i].product_promotion.value
        let promotionType = items[i].product_promotion && items[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        total += price * items[i].quantity - promotionPrice
      }
      total = total > 0 ? total : 0
      return total
    },
    /**
     * Get voucher product
     */
    getOrdersVoucherProduct: function () {
      this.isLoading = true
      let startTime = dateUtils.formatCompareDate(this.startDateFormatted, null)
      let endTime = dateUtils.formatCompareDate(this.endDateFormatted, null)
      let filter = {
        params: {
          page: this.productPaginate.currentPage,
          code: this.search.code ? this.search.code.trim() : null,
          status: this.search.status,
          emailOrPhone: this.search.emailOrPhone,
          statusList: this.search.statusList,
          agentId: this.agentId,
          storeChannelId: this.storeChannelId,
          startDate: startTime,
          endDate: endTime,
          paymentMethod: this.paymentMethod.id
        }
      }
      this.GET_VOUCHER_PRODUCT_ORDERS(filter)
        .then(
          function (res) {
            let data = res.data
            // Handle paginate
            this.productPaginate.currentPage = data.page
            this.productPaginate.totalPage = data.num_pages
            this.productPaginate.rowPerPage = data.per_page / 2
            // Handle response data
            let productData = data.results
            this.productOrderList = []
            for (let i = 0, size = productData.length; i < size; i++) {
              let dateCreated = dateUtils.formatBeautyDate(productData[i].date_created)
              let promotionCode = productData[i].promotion_code
              let promotionOrderList = promotionCode.filter(
                x => x.apply_type === VoucherProductApplyType.ORDER)
              let productList = productData[i].items
              let totalPrice = this.getTotalPrice(productList)
              let totalPromotionOrderPrice = this.getPromotionPriceOrder(totalPrice, promotionOrderList)
              let object = {
                stt: i + 1 + (data.page - 1) * data.page_size,
                orderCode: productData[i].code,
                date: dateCreated,
                deadlinePayment: moment(dateCreated, 'DD/MM/YYYY HH:mm').add(this.holdingTime, 'hours').format('YYYY-MM-DD HH:mm'),
                status: productData[i].status,
                totalPrice: totalPrice - totalPromotionOrderPrice,
                fullname: functionUtils.concatFullname(productData[i].user_order.last_name, productData[i].user_order.first_name),
                voucherList: productData[i].items,
                userOrder: productData[i].user_order,
                id: productData[i].id,
                email: productData[i].user_order.email,
                paidDate: dateUtils.formatBeautyDate(productData[i].date_updated),
                agent: productData[i].entity.name,
                discount: this.getDiscount(
                  productData[i].promotion_type,
                  productData[i].promotion_value
                ),
                phone: productData[i].user_order.phone,
                paymentMethod: productData[i].payment_method,
                paymentOverdue: false,
                storeChannel: productData[i].store_channel.name,
                approveType: productData[i].approve_type,
                totalPromotionOrderPrice: totalPromotionOrderPrice
              }
              this.productOrderList.push(object)
            }
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    // Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.productPaginate.currentPage = page
      this.getOrdersVoucherProduct()
    },
    getPaymentMethodName: function (method) {
      return functionUtils.paymentMethodTypeName(method)
    },
    getPromotionPriceOrder: function (voucherTotalPrice, promotionOrderList) {
      let result = 0
      promotionOrderList.forEach(e => {
        if (e.promotion_type === VoucherProductPromotionType.PRICE) {
          result += e.value
        } else if (e.promotion_type === VoucherProductPromotionType.PERCENT) {
          result += voucherTotalPrice * e.value / 100
        }
      })
      return result
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'GET_VOUCHER_PRODUCT_ORDERS',
      'PAYMENT_CONFIRMATION',
      'CANCEL_VOUCHER_PRODUCT_ORDER',
      'GET_VOUCHER_PRODUCT_IN_ORDERS',
      'GET_ENTITIES_OF_USER',
      'GET_STORE_CHANNELS_LIST',
      'PAYMENT_CONFIRMATION_BULK',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner-img-class {
  width: 80%;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-banner-img {
  color: #ef5350;
  cursor: pointer;
}
.remove-banner-img:hover {
  text-decoration: underline;
}
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
