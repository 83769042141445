<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('portal.emailDomain.importExcel')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <div
            class="float-right mr-2 pb-4"
          >
            <upload-excel
              :on-success="handleSuccess"
              :before-upload="beforeUpload"/>
            <div
              class="mt-4">
              <span>{{ $t('distributeExcelMode.preview') }}</span>
              <div style="max-height: 300px; overflow-y: auto;">
                <table
                  class="mt-2"
                  style="width:100%;">
                  <tr>
                    <th
                      v-for="(header, index) in excelListDomainEmail.headers"
                      :key="header + index">
                      {{ header }}
                    </th>
                  </tr>
                  <tr
                    v-for="(result, index) in excelListDomainEmail.results"
                    :key="result + index">
                    <td
                      v-for="(header, index) in excelListDomainEmail.headers"
                      :key="header + index">
                      <span
                        v-if="header == 'Status / Trạng thái'"
                        :style="result[header] == 'Thành công' ? 'color: #66bb6a;' : 'color: red;'">
                        {{ result[header] }}
                      </span>
                      <span v-else>
                        {{ result[header] }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mt-2">
                <div v-if="excelListDomainEmail.countErrorProcessing > 0 && excelListDomainEmail.countSuccessedProcessing > 0">
                  <span
                    style="display: block;"
                    class="font-italic font-weight-black">{{ $t('importExcelApproveOrder.thereAreInvalidOrders') }}</span>
                  <span
                    class="font-italic font-weight-bold"
                    style="color: red;">{{ $t('distributeExcelMode.notice') }}: </span> <span class="font-italic font-weight-black">{{ $t('importExcelApproveOrder.ifContinueInvalidOrdersNotBeApproved') }}</span>
                </div>
                <v-btn
                  v-if="excelListDomainEmail.countErrorProcessing > 0 && excelListDomainEmail.countSuccessedProcessing > 0"
                  :loading="isLoading"
                  style="display: block; margin-left: 0; margin-right: 0;"
                  small
                  color="success darken-1"
                  @click="onConfirm(isForceApprove = true)"
                >{{ $t('common.continue') }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          v-if="!hideBtnConfirm"
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm(isForceApprove = false)"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UploadExcel from 'Components/UploadExcel'
// import DomainType from 'enum/domainType'
export default {
  components: {
    UploadExcel
  },
  data: () => ({
    excelListDomainEmail: {
      results: [],
      headers: [],
      countErrorProcessing: 0,
      countSuccessedProcessing: 0
    },
    isShowModal: false,
    isForceApprove: false,
    hideBtnConfirm: false,
    isLoading: false
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  methods: {
    /**
     * Handle success upload file excel
     */
    handleSuccess: function ({ results, header }) {
      this.excelListDomainEmail.results = results
      this.excelListDomainEmail.headers = header
      this.hideBtnConfirm = false
      this.excelListDomainEmail.countErrorProcessing = 0
      this.excelListDomainEmail.countSuccessedProcessing = 0
    },
    /**
     * Before upload file excel
     */
    beforeUpload: function (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      return false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (!this.isForceApprove) {
        this.excelListDomainEmail.countErrorProcessing = 0
        this.excelListDomainEmail.countSuccessedProcessing = 0
      }
      let filter = {
        headers: this.excelListDomainEmail.headers,
        dataImport: this.excelListDomainEmail.results,
        isForceApprove: this.isForceApprove
      }
      this.isLoading = true
      this.ON_APPROVE_ORDER_BY_EXCEL(filter).then(
        function (res) {
          let data = res.data
          this.isLoading = false
          if (this.isForceApprove) {
            this.isForceApprove = false
            this.excelListDomainEmail.countErrorProcessing = 0
            this.excelListDomainEmail.countSuccessedProcessing = 0
          } else {
            this.excelListDomainEmail.countErrorProcessing = data.error_processing_count
            this.excelListDomainEmail.countSuccessedProcessing = data.successed_processing_count
          }
          this.excelListDomainEmail.headers = data.headers
          this.excelListDomainEmail.results = data.response_list
          this.hideBtnConfirm = true
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Show modal
     */
    onShowModal: function () {
      this.excelListDomainEmail = {
        results: [],
        headers: [],
        countErrorProcessing: 0,
        countSuccessedProcessing: 0
      }
      this.isShowModal = true
      this.hideBtnConfirm = false
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.$emit('success')
    },
    ...mapActions([
      'ON_APPROVE_ORDER_BY_EXCEL'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
table, th {
    white-space: nowrap;
}
</style>
