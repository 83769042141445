<template>
  <v-dialog
    v-model="isShowModal"
    max-width="1000px">
    <v-card>
      <material-card
        :title="`${$t('productOrder.order')} ${code} ${$t('productOrder.detail')}`"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout
              wrap
              layout>
              <!-- Date -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="date"
                  :label="$t('users.dateCreated')"
                  :name="$t('users.dateCreated')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- Status -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="status"
                  :label="$t('supplierAccessCode.status')"
                  :name="$t('supplierAccessCode.status')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- Email -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="email"
                  :label="$t('users.email')"
                  :name="$t('users.email')"
                  type="text"
                  disabled
                />
              </v-flex>
              <!-- Fullname -->
              <v-flex
                xs12
                md6>
                <v-text-field
                  v-model="fullname"
                  :label="$t('evoucher.customer')"
                  :name="$t('evoucher.customer')"
                  type="text"
                  disabled
                />
              </v-flex>
              <v-flex
                xs12
                md12>
                <v-data-table
                  :no-data-text="$t('common.noDataAvailable')"
                  :headers="voucherHeaders"
                  :items="vouchersList"
                  :single-select="true"
                  hide-actions
                >
                  <template
                    slot="headerCell"
                    slot-scope="{ header }">
                    <span
                      class="text--darken-3 font-medium"
                      v-text="$t(header.text)"
                    />
                  </template>
                  <template
                    slot="items"
                    slot-scope="{ item }">
                    <td>{{ item.stt }}</td>
                    <td>{{ item.product }}</td>
                    <td>{{ formatMoney(item.price) + ' VND' }}</td>
                    <td>{{ 'x' + item.quantity }}</td>
                    <td>
                      <div v-if="item.promotionValue !== null">
                        <span
                          v-if="item.promotionType === voucherProductPromotionType.PERCENT">
                          {{ item.promotionValue + ' %' }} ({{ $t('evoucher.discountPrice') }} {{ formatMoney(item.promotionPrice) + ' VND' }})</span>
                        <span v-else> {{ formatMoney(item.promotionValue) + ' VND' }}</span>
                      </div>
                    </td>
                    <td class="text-right">{{ formatMoney(item.totalPrice) + ' VND' }}</td>
                  </template>
                  <template v-slot:footer>
                    <tr>
                      <td
                        colspan="5"
                        class="text-right">{{ $t('productOrder.tableList.header.totalPrice') }}</td>
                      <td class="text-right">{{ formatMoney(totalPrice) + ' VND' }}</td>
                    </tr>
                    <tr>
                      <td
                        colspan="5"
                        class="text-right">{{ $t('paymentMethod.discountOnBill') }}</td>
                      <td class="text-right">{{ formatMoney(totalPromotionOrderPrice) + ' VND' }}</td>
                    </tr>
                    <tr>
                      <td
                        colspan="5"
                        class="text-right">{{ $t('cart.promotion.totalPriceToPay') }}</td>
                      <td class="text-right">{{ formatMoney(finalTotalPrice) + ' VND' }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
    </v-card>
  </v-dialog>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import ProductOrderStatusEnum from 'enum/productOrderStatusEnum'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
export default {
  data: () => ({
    voucherHeaders: [
      {
        sortable: false,
        text: '#',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'voucherProduct.productTitle',
        value: 'product'
      },
      {
        sortable: false,
        text: 'evoucher.issuance.price',
        value: 'price'
      },
      {
        sortable: false,
        text: 'evoucher.quantity',
        value: 'quantity'
      },
      {
        sortable: false,
        text: 'cart.promotion.discount',
        value: 'promotionValue'
      },
      {
        sortable: false,
        text: 'workpieceDetail.totalPrice',
        value: 'totalPrice',
        class: 'text-right'
      }
    ],
    vouchersList: [],
    valid: false,
    isShowModal: false,
    date: null,
    status: null,
    fullname: null,
    phone: null,
    email: null,
    code: null,
    voucherProductPromotionType: VoucherProductPromotionType,
    totalPrice: 0,
    totalPromotionOrderPrice: 0,
    finalTotalPrice: 0
  }),
  methods: {
    /**
    * Show modal
    */
    onShowModal: function (data) {
      this.isShowModal = true
      let statusText = this.getStatus(data.status)
      this.date = data.date
      this.status = statusText
      this.fullname = data.fullname
      this.email = data.userOrder.email
      this.code = data.orderCode
      this.vouchersList = []
      let voucherList = data.voucherList
      this.totalPromotionOrderPrice = 0
      this.totalPrice = 0
      for (let i = 0, size = voucherList.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(voucherList[i].price)
          ? voucherList[i].price : voucherList[i].workpiece.value
        let promotionValue = voucherList[i].product_promotion && voucherList[i].product_promotion.value
        let promotionType = voucherList[i].product_promotion && voucherList[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        let voucherTotalPrice = price * voucherList[i].quantity - promotionPrice
        voucherTotalPrice = voucherTotalPrice > 0 ? voucherTotalPrice : 0
        let obj = {
          stt: i + 1,
          quantity: voucherList[i].quantity,
          product: voucherList[i].workpiece.name,
          price: price,
          totalPrice: voucherTotalPrice,
          promotionValue: !functionUtils.isEmptyString(promotionValue) ? promotionValue : null,
          promotionType: promotionType,
          promotionPrice: promotionPrice
        }
        this.vouchersList.push(obj)
        this.totalPrice += voucherTotalPrice
      }
      this.totalPrice = this.totalPrice > 0 ? this.totalPrice : 0
      this.totalPromotionOrderPrice = data.totalPromotionOrderPrice
      this.finalTotalPrice = this.totalPrice - this.totalPromotionOrderPrice
      this.finalTotalPrice = this.finalTotalPrice > 0 ? this.finalTotalPrice : 0
    },
    /**
     * Get status
     */
    getStatus: function (status) {
      // eslint-disable-next-line eqeqeq
      if (status == ProductOrderStatusEnum.DELIVERED) {
        return this.$t('productOrder.status.delivered')
      // eslint-disable-next-line eqeqeq
      } else if (status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        return this.$t('productOrder.status.waitForPay')
      // eslint-disable-next-line eqeqeq
      } else if (status == ProductOrderStatusEnum.CANCEL) {
        return this.$t('productOrder.status.cancel')
      } else if (status === ProductOrderStatusEnum.PAID_ONLINE) {
        return this.$t('productOrder.status.paid')
      }
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.footer-cell-price {
  width: fit-content;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px
}
/deep/ table.v-table tfoot td {
  padding: 12px 8px;
}
</style>
